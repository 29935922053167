import React from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import Header from './components/Header';
import FeaturedContent from './components/FeaturedContent';

export const App = ({ store }) => {
  return (
    <Provider store={store}>
      <div>
        <Header />
        <FeaturedContent />
      </div>
    </Provider>
  );
};

App.propTypes = {
  store: PropTypes.object.isRequired
};

export default App;

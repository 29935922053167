import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import MarkdownIt from 'markdown-it';

export class Description extends React.Component {
  render() {
    const md = new MarkdownIt({
      linkify: true
    });
    const { descriptionText } = this.props;
    const markedDescription = { __html: md.render(descriptionText || '') };
    const classNames = ['clp-description', window.location.hostname.replace(/[^\w]+/g, '_')];

    return <div className={classNames.join(' ')} dangerouslySetInnerHTML={markedDescription} />;
  }
}

Description.propTypes = {
  descriptionText: PropTypes.string.isRequired
};

const mapStateToProps = (state) => ({
  descriptionText: state.header.description
});

export default connect(mapStateToProps)(Description);

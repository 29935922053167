import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';

import Description from './Description';
import { currentUserHasRight } from 'common/current_user';
import * as Rights from 'common/rights';

const Header = (props) => {
  const { headline } = props;

  let managementPageHref;
  if (_.get(props, 'query.custom_path')) {
    managementPageHref = `/catalog_landing_page/manage?custom_path=${props.query.custom_path}`;
  } else {
    managementPageHref = `/catalog_landing_page/manage${window.location.search}`;
  }

  const toggleThrobber = () => {
    $('.management-button .throbber-icon, .management-button .socrata-icon-arrow-right').toggle();
  };

  const renderManagementButton = () => {
    if (document.querySelector('.alert.info.browse2-manage-catalog-landing-page')) {
      return null;
    }

    return (<a className="management-button-anchor"
               href={managementPageHref}
               onClick={toggleThrobber}
               aria-label={_.get(I18n, 'manager.edit_action', 'Edit Featured Content')}>
      <button className="management-button alert info"
              aria-label={_.get(I18n, 'manager.edit_action', 'Edit Featured Content')}>
        <i id="socrata-icon-edit" className="socrata-icon-edit"></i>
        <span className={headline ? 'hoverText' : null}>
          {_.get(I18n, 'manager.edit_action', 'Edit Featured Content')}
        </span>
        <span className="throbber-icon"></span>
      </button>
    </a>);
  };

  return (
    <div className="catalog-landing-page-header">
      {headline ?
      <h1>
        {headline}
        {currentUserHasRight(Rights.FEATURE_ITEMS) && renderManagementButton()}
      </h1> :
      <div className="management-button-container">
        {currentUserHasRight(Rights.FEATURE_ITEMS) && renderManagementButton()}
      </div>}
      <Description />
    </div>
  );
};

Header.propTypes = {
  headline: PropTypes.string,
  query: PropTypes.object.isRequired
};

const mapStateToProps = (state) => {
  return _.assign({}, state.header, { query: state.catalog.query });
};

export default connect(mapStateToProps)(Header);

import PropTypes from 'prop-types';
import React from 'react';
import ViewCard, { ExternalViewCard } from 'common/components/ViewCard';

export class FeaturedContentViewCard extends React.Component {
  render() {
    const { resource_id: key, catalogFederated, children, linkProps } = this.props;

    if (this.props.contentType === 'external') {
      return <ExternalViewCard key={key} children={children} {...this.props} />;
    } else {
      const linkPropsToOpenInNewTab = {
        ...linkProps,
        target: '_blank',
        rel: 'external'
      };
      return <ViewCard
        key={key} children={children} {...this.props}
        linkProps={ catalogFederated ? linkPropsToOpenInNewTab : linkProps}
      />;
    }
  }
}

FeaturedContentViewCard.propTypes = {
  children: PropTypes.object,
  contentType: PropTypes.string.isRequired,
  description: PropTypes.string,
  icon: PropTypes.string,
  imageUrl: PropTypes.string,
  isPrivate: PropTypes.bool,
  linkProps: PropTypes.object,
  metadataLeft: PropTypes.string,
  metadataRight: PropTypes.string,
  name: PropTypes.string.isRequired,
  resource_id: PropTypes.number,
  url: PropTypes.string
};

FeaturedContentViewCard.defaultProps = {
  description: '',
  position: 0,
  name: '',
  url: '',
  viewCount: 0
};

export default FeaturedContentViewCard;

/**
  This file is a slimmed down version of screens/browse2.js to support the basic catalog functionality
  needed for a Catalog Landing Page.
  browse2.js cannot be used in a page using the styleguide layout, because things like window.blist are
  undefined.

  Long-term TODO: re-build the catalog as a React Component.
*/

import _ from 'lodash';
import $ from 'jquery';
import '@socrata/jquery-ui';
import { trapFocus, unTrapFocus } from 'common/a11y/focus-trap';

import collapsible from 'common/collapsible';
import I18n from 'common/i18n';
import { isLargeDesktop } from 'common/visualizations/helpers/MediaQueryHelper';
import FeatureFlags from 'common/feature_flags';


function isMobileMode() {
  //Styles for the facet filters go into MobileMode below LARGE_DESKTOP_WIDTH
  return (!isLargeDesktop());
}
const showBrowse2MobileLoadingSpinner = () => {
  $('.browse2-loading-spinner-container').show();
};

const hideBrowse2MobileLoadingSpinner = () => {
  $('.browse2-loading-spinner-container').hide();
};

const hideParentAlert = (event) => {
  $(event.target).closest('.alert').hide();
};

const hideCLPManager = () => {
  document.cookie = 'hide-clp-manager=true; expires=0; path=/';
  $('.browse2-manage-catalog-landing-page').hide();
};

const showCLPHelpFlyout = () => {
  $('#clp-help-flyout').show();
};

const hideCLPHelpFlyout = () => {
  $('#clp-help-flyout').hide();
};

const doBrowse = (newOpts) => {
  // Reset page
  delete newOpts.page;

  const baseUrl = window.location.href.split('?')[0];
  const newUrlParams = _.map(newOpts, function(value, key) {
    if (_.isArray(value)) {
      return _.map(value, function(subValue) {
        return `${key}=${subValue}`;
      }).join('&');
    }
    return `${key}=${value}`;
  }).join('&');

  window.location = `${baseUrl}?${newUrlParams}`;
};

const toggleBrowse2FacetDisplay = (event, target) => {
  const $sectionContainer = $(target).closest('.browse2-facet-section');
  const currentDisplay = $sectionContainer.attr('data-facet-display');

  if (currentDisplay === 'show') {
    $sectionContainer.attr('data-facet-display', 'hide');
    $sectionContainer.find('a').attr('aria-expanded', 'false');
  } else {
    $sectionContainer.attr('data-facet-display', 'show');
    $sectionContainer.find('a').attr('aria-expanded', 'true');
  }

  $sectionContainer.children('ul.browse2-facet-section-options').slideToggle('fast');
};

const toggleBrowse2FacetChildOptionDropdown = (event) => {
  event.preventDefault();
  event.stopPropagation();
  const childOptionToggle = $(event.target).closest('li').find('.browse2-facet-section-child-option-toggle');
  const childOptionExpanded = childOptionToggle.attr('aria-expanded') === 'true';
  childOptionToggle.attr('aria-expanded', !childOptionExpanded);
  $(event.target).closest('li').find('.browse2-facet-section-child-options').slideToggle('fast');
};

function toggleBrowse2FacetFilterRadioButton(event) {
   event.preventDefault();
   $(event.currentTarget.parentElement).find('a')[0].click();
 }


// Mobile menu
const toggleBrowse2MobileFacetsSlideout = (event) => {
  event.preventDefault();
  const $facetPaneSection = $('.browse2-facets-pane, .browse2-mobile-facets-filter-button');
  // Slide out other main elements on page when the mobile facet section slides in
  const $mainPageElements = $(
    ['.siteContentWrapper > ul.featuredViews', '.browse2-search', '.browse2-mobile-filter',
      '.browse2-results-pane', '#siteHeader', '#site-chrome-header', '#siteFooter', '#site-chrome-footer'
    ].join(', ')
  );

  const action = 'slide';
  const time = 200;
  if (FeatureFlags.value('accessible_browse_filters')) {
    var $mobileFiltersSection = $('#mobile-accessible-browse-filters');
    $mobileFiltersSection.show(action, {
     direction: 'left'
     }, time);
     $mainPageElements.hide(action, {
       direction: 'right'
     }, time);
  } else {
    if ($facetPaneSection.is(':visible')) {
      $mainPageElements.show(action, {
        direction: 'right'
      }, time);
      $facetPaneSection.hide(action, {
        direction: 'left'
      }, time);
    } else {
      $facetPaneSection.show(action, {
        direction: 'left'
      }, time);
      $mainPageElements.hide(action, {
        direction: 'right'
      }, time);
    }
   }
};

// When the user clicks a filter option in the active filter section, it means they are
// clearing the filter. This finds the clicked option in the facet dropdown section below
// and removes the "active" class, then removes the option from the active filter section.
const browse2MobileActiveFilterClick = (event) => {
  const facetOptionType = $(event.target).data('facetOptionType');
  const facetOptionValue = $(event.target).data('facetOptionValue');
  const $facetOption = $(`.browse2-facet-section[data-facet-option-type="${facetOptionType}"]`).
    find(
      `.browse2-facet-section-option[data-facet-option-value="${facetOptionValue}"], ` +
      `.browse2-facet-section-child-option[data-facet-option-value="${facetOptionValue}"]`
    );
  $facetOption.removeClass('active');
  // Remove option from active filter section
  $(event.target).fadeOut('fast');
};

const browse2MobileFacetClick = (event) => {
  // Not MobileMode: return and let the facet follow its href.
  if (!isMobileMode()) {
    return;
  }

  // In MobileMode: prevent the link href and toggle the 'active' class
  event.preventDefault();
  const facetOptionIsCurrentlyActive = $(event.target).hasClass('active');

  // There can only be one active facet per section. Remove other active facet if any.
  $(event.target).
    closest('.browse2-facet-section').
    find('.active').
    removeClass('active');

  if (!facetOptionIsCurrentlyActive) {
    $(event.target).addClass('active');
  }
};

const filterBrowse2MobileFacets = () => {
  const facetFilters = {};
  let urlParams = {};

  // Get all filter facet options that are currently active and store them in facetFilters
  $('.browse2-facet-section-option.active, .browse2-facet-section-child-option.active').each((i, option) => {
    const paramKey = $(option).closest('.browse2-facet-section').data('facetOptionType');
    const paramValue = $(option).data('facetOptionValue');
    facetFilters[paramKey] = paramValue;
  });

  // parse the old search params and validate them
  const oldUrlParamString = location.search.substring(1);
  if (oldUrlParamString) {
    const oldSearchParams = new URLSearchParams(decodeURI(oldUrlParamString));
    const filters = $('.browse2-facet-section');
    const filterNames = _.map(filters, (filter) => {
      return filter.attributes['data-facet-option-type'].textContent;
    });
    for (let param of oldSearchParams) {
      if (filterNames.indexOf(param[0]) >= 0) {
        urlParams[param[0]] = param[1];
      }
    }
  }

  // Remove all existing filter facets from url params
  $('.browse2-facet-section').each((i, section) => {
    const facetType = $(section).data('facetOptionType');
    delete urlParams[facetType];
  });

  // Also delete the "page" offset parameter if it is present
  delete urlParams.page;

  // Add the current active facetFilters into the urlParams
  urlParams = $.param(_.merge(urlParams, facetFilters));

  // Render a loading spinner and open the new url.
  showBrowse2MobileLoadingSpinner();

  window.addEventListener('pagehide', () => {
    hideBrowse2MobileLoadingSpinner();
  });

  // Safari needs an extra frame before loading the new page to render the loading spinner
  _.defer(() => {
    location.href = `/browse?${urlParams}`;
  });
};

const browse2MobileFacetClearAll = (event) => {
  event.preventDefault();
  $('.browse2-facet-section-option.active, .browse2-facet-section-child-option.active').
    removeClass('active');
  filterBrowse2MobileFacets();
};

// Store the overflow property values as data attributes on the facet modal so we can restore
// it once the modal is hidden.
const storeOverflowPropertyInModal = (element) => {
  const overflowX = $(element).css('overflow-x');
  const overflowY = $(element).css('overflow-y');
  $('.browse2-facet-section-modal').data(`${element}OverflowX`, overflowX);
  $('.browse2-facet-section-modal').data(`${element}OverflowY`, overflowY);
};

const restoreOverflowProperty = (element) => {
  const overflowX = $('.browse2-facet-section-modal').data(`${element}OverflowX`);
  const overflowY = $('.browse2-facet-section-modal').data(`${element}OverflowY`);
  const defaultOverflow = 'initial';
  $(element).css({
    'overflow-x': overflowX || defaultOverflow,
    'overflow-y': overflowY || defaultOverflow
  });
};

const hideBrowse2FacetModal = () => {
  $('.browse2-facet-section-modal').addClass('hidden');
  unTrapFocus();
  restoreOverflowProperty('html');
  restoreOverflowProperty('body');
  $('.siteOuterWrapper').attr('aria-hidden', false);
};

const hideBrowse2FacetModalOnEscape = () => {
  $(document).keyup((e) => {
    if (e.keyCode === 27) {
      hideBrowse2FacetModal();
    }
  });
};

const showBrowse2FacetModal = (event) => {
  event.preventDefault();
  // Set height of modal based on user's window size
  const modalVerticalMargins = 40;
  const modalHeaderFooterHeight = 120;
  const modalContentMaxHeight = window.innerHeight - (modalVerticalMargins * 2) - modalHeaderFooterHeight;
  $('.browse2-facet-section-modal-container').css({
    'margin': `${modalVerticalMargins}px auto`
  });
  $('.browse2-facet-section-modal-content').css({
    'max-height': `${modalContentMaxHeight}px`
  });
  // Prevent the normal body scroll and show the modal
  storeOverflowPropertyInModal('html');
  storeOverflowPropertyInModal('body');
  $('html, body').css('overflow', 'hidden');
  const chosenFacet = $(event.currentTarget).data('modalFacet');
  $(`.browse2-facet-section-modal[data-modal-facet="${chosenFacet}"]`).removeClass('hidden');
  trapFocus($(`.browse2-facet-section-modal[data-modal-facet="${chosenFacet}"] .browse2-facet-section-modal-container`)[0], document.activeElement);
  $('.browse2-facet-section-modal-container h1').focus();
  $('.siteOuterWrapper').attr('aria-hidden', true);
  hideBrowse2FacetModalOnEscape();
};

const truncateDescription = (element) => {
  const scope = 'controls.browse.browse2.description';
  collapsible(element, {
    lines: 2,
    toggleText: {
      more: I18n.t('show_more_label', { scope }),
      less: I18n.t('show_less_label', { scope })
    }
  });
};

// This is a hack to make sure users know how many results are present.
// Making the result count visible will activate the aria-alert on the div.
$(window).on('load', () => {
  $('.browse2-result-count.result-count.hidden').removeClass('hidden');
});

// The `no results` span is high enough in the tree
// that when it loads it gets ignored because the screen reader is saying
// something else. That's not how it should work, but...
$(window).on('load', () => {
  setTimeout(() => {
    $('.browse2-no-results-message.hidden').removeClass('hidden');
  }, 3000);
});

$(document).ready(() => {
  const opts = {};
  const $facetHeaders = $('.browse2-facet-section-title');
  const $sortType = $('#browse2-sort-type');

  if (!_.isEmpty(window.location.search)) {
    _.each(
      window.location.search.slice(1).split('&'),
      function(urlParam) {
        const urlParamKey = unescape(urlParam.split('=')[0]);
        const urlParamValue = urlParam.split('=')[1];

        if (/\[\]$/.test(urlParamKey)) {
          if (_.isEmpty(opts[urlParamKey])) {
            opts[urlParamKey] = [];
          }

          opts[urlParamKey].push(urlParamValue);
        } else {
          opts[urlParamKey] = urlParamValue;
        }
      }
    );
  }

  // Expand facet child options list by default if it contains an "active" option
  $('.browse2-facet-section-child-option.active').closest('.browse2-facet-section-child-options').show();

  // Select the radio button associated with active facets on load
  $('.browse2-facet-section-option.active, .browse2-facet-section-option.has-child-options.active, .browse2-facet-section-child-option.active').each(function(index, element) {
    $(element.parentElement).children('.browse2-facet-section-option-radio-button').prop('checked', true);
  });

  // Collapse facet options by default in MobileMode
  if (isMobileMode()) {
    toggleBrowse2FacetDisplay(null, $('.browse2-facet-section-title'));
    $('ul.browse2-facet-section-options').hide();
  }

  // Don't forget to also check here: platform-ui/frontend/public/javascripts/screens/browse2.js
  // Result description truncation
  $('.browse2-result-description').each((index, element) => {
    truncateDescription(element);
  });

  // Listeners
  $.fn.extend({
    onClickOrEnter: function(callback) {
      return this.on('click', function(event) { callback(event); }).
        keyup(function(event) {
          if (event.keyCode === 13) {
            callback(event);
          }
        });
    }
  });

  $facetHeaders.onClickOrEnter(function(event) { toggleBrowse2FacetDisplay(event, event.target); });

  $sortType.on('change', () => {
    _.defer(() => {
      const newOpts = $.extend({}, opts);
      newOpts.sortBy = $sortType.val();
      doBrowse(newOpts);
    });
  });

  $('.browse2-facet-section-child-option-toggle').onClickOrEnter(
    function(event) { toggleBrowse2FacetChildOptionDropdown(event); }
  );
  $('.browse2-facet-section-modal-button').on('click', showBrowse2FacetModal);
  $('.browse2-facet-section-modal-close, .modal-close-button').on('click', hideBrowse2FacetModal);
  $('.browse2-facet-section-modal-background').
    onClickOrEnter(hideBrowse2FacetModal);
  $('.manage-clp-hide-action').onClickOrEnter(hideCLPManager);
  $('.close-alert').onClickOrEnter(event=>hideParentAlert(event));
  $('#clp-help-toggle').on('mouseover', showCLPHelpFlyout).on('mouseout', hideCLPHelpFlyout);
  $('.browse2-facet-section-option-radio-button').
    on('change', function(event) { toggleBrowse2FacetFilterRadioButton(event); });

  // Mobile menu listeners
  $('.browse2-mobile-filter, .browse2-facets-pane-mobile-header').
    on('click', toggleBrowse2MobileFacetsSlideout);
  $('.browse2-facets-mobile-active-filter').on('click', browse2MobileActiveFilterClick);
  $('.browse2-facet-section-option, .browse2-facet-section-child-option').
    on('change', browse2MobileFacetClick);
  $('.browse2-mobile-facets-filter-button').on('click', filterBrowse2MobileFacets);
  $('.browse2-facets-pane-mobile-clear-all-button').on('click', browse2MobileFacetClearAll);
});
